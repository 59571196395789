<template>
  <div class="disclaimer">
    当サイトで表示している所要時間はあくまで目安です。正確な所要時間は各種乗り換え検索サービスでご確認ください。<br>
    機能の要望やバグの報告は<a href="https://forms.gle/ibzN81KRWVXykMP76" target="blank">こちら</a>にお願い致します。
  </div>
</template>

<script>
export default {
  name: 'disclaimer',
}
</script>

<style>
.disclaimer{
  /* border: 2px solid black; */
  font-size: 11px;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  text-align: center;
}
</style>
