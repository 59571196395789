Vue.config.devtools = true;
import Vue from 'vue'
import Meta from 'vue-meta';
import Ads from 'vue-google-adsense'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import firebase from 'firebase'
import { firebaseConfig, google_map_key } from '../firebase-config'

// Adsense
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense) //広告ユニット
Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-1040333504284501' })
Vue.use(Meta);
Vue.use(require('vue-script2'))
Vue.use(BootstrapVue)

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.use(VueGoogleMaps, {
  load: {
    // 外出しする
    key: google_map_key,
    libraries: 'places',
    region: 'JP',
    language: 'ja'
  },
  installComponents: true
})
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
