<template>
  <div class="available_line">
    1) 都内主要路線の各駅に対応しています
  </div>
</template>

<script>
export default {
  name: 'available_line',
}
</script>

<style>
.available_line{
  /* border: 2px solid black; */
  font-size: 11px;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  text-align: center;
}

</style>
