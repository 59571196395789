<template>
  <div id="app">
    <H1 id="title">{{title}}</H1>
    <search></search>
  </div>
</template>

<script>
import search from './components/Search'
export default {
  name: 'app',
  created: function(){
    // これでtitleをセットするとdefaultのtuukin-jikan-searchという
    // 文字列が表示されてしまう、VueMeta pkgで解決できる？
    // https://reactgo.com/vue-set-title/
    document.title = "通勤圏サーチ"
  },
  components: {
    search
    },
  data () {
    return{
      title: '通勤圏サーチ：通勤時間から住む街を探す'
    }
  },
  metaInfo: {
  link: [
    // firebaseのurlに直接飛ばれたときにURLを正規のものと判定するよう
    // firebaseの方に飛ばれる状況が想像できないが念の為
    {rel: 'canonical', href: 'https://tuukinken-search.neko-ni-naritai.com'}
  ]
}
}
</script>

<style>
#title{
  padding: 5px;
  font-size: 30px;
  text-align: center;
  color: white;
	background-color:#28a745;
;
}

</style>
