
// firebaseのAPI Key
// Private Keyと違ってfirestore ruleの影響を受ける
// Pribate keyは Firestoreに対するReadもWriteもセキュリティルールに関係なく可能
// https://qiita.com/mtskhs/items/f2abba5f284dbb446e0f

export const firebaseConfig = {
    apiKey: "AIzaSyCNSzYCwbfHMWCs-lXU6G2YAeELn1xQ3QQ",
    authDomain: "tuukin-jikan-search.firebaseapp.com",
    databaseURL: "https://tuukin-jikan-search.firebaseio.com",
    projectId: "tuukin-jikan-search",
    storageBucket: "tuukin-jikan-search.appspot.com",
    messagingSenderId: "912662889484",
    appId: "1:912662889484:web:b265f0f2cb28c5aa2b1a29",
    measurementId: "G-N7G5QRFCKX"
};

export const google_map_key = 'AIzaSyDBg96Kh_RYEt4p2m6VtnbJ2EP-vZVDI2w'