<template>
  <div class="notice">
    お知らせ：当アプリは作者多忙につきアップデートが出来ていませんでしたが、頂いている要望への対応や機能追加を本年順次実施予定です。
  </div>
</template>

<script>
export default {
  name: 'notice',
}
</script>

<style>
.notice{
  /* border: 2px solid black; */
  font-size: 11px;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  text-align: center;
}
</style>
